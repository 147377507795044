import React from "react";
import { IoCloseSharp } from "react-icons/io5";

const AppReleaseModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center flex-col bg-black bg-opacity-80 z-50 p-4">
      {/* Close button */}
      <div className="cursor-pointer rounded-full bg-white  mb-3 p-1 border border-gray-400">
        <div className="flex items-center justify-center">
          <IoCloseSharp onClick={onClose} className="w-7 h-7 text-black" />
        </div>
      </div>

      {/* Modal content */}
      <div className="bg-white px-8 py-6 mb-2 rounded-lg shadow-lg max-w-xl  w-full text-center">
        <h2 className="text-2xl font-bold mb-4">App Released!</h2>
        <p className="text-gray-700 mb-8">
          Download now and explore the services tailored to your needs!{" "}
        </p>

        <div className="bg-green-900 p-4 rounded-lg ">
            <h3 className="text-lg font-semibold text-white mb-2">
              GrowTo Busniess App
            </h3>
            <div className="max-w-xl mx-auto text-gray-100 text-sm mb-6">
              <ul className="text-start list-disc pl-5 space-y-2">
                <li>
                  <strong>Get consistent orders</strong> nearby.
                </li>
                <li>
                  <strong>List & Earn in Lakh/month</strong> for free.
                </li>
                <li>
                  <strong>100+ users already registered.</strong>
                </li>
              </ul>
            </div>

            <a
              href="https://mugesh-rao.github.io/CashTrack/GrowTo-Biz.apk"
              download="GrowTo-Biz.apk"
              className="block w-full font-bold bg-white text-green-900 py-2 rounded-lg hover:bg-green-50 transition duration-300 text-center"
            >
              Download GrowTo Partner
            </a>
          </div>
      </div>
    </div>
  );
};

export default AppReleaseModal;
