import React, { useState } from "react";
import logo from "../Asset/logo-growto-secondary.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  FaFile,
  FaFileAlt,
  FaIdCard,
  FaImages,
  FaMagic,
  FaRegMehBlank,
} from "react-icons/fa";
import Footer from "../Layouts/Footer";
import AppReleaseModal from "../Components/Helpers/AppReleaseModal";
import Registartion from "../Components/Home/Registartion";

function Home() {
  const navigate = useNavigate("/Home"); // Hook to control navigation
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = () => {
    // Set flag in localStorage so the modal doesn't show again

    setShowModal(false);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/home");
    }
  }, []);
  return (
    <div>
       {showModal && <AppReleaseModal onClose={handleCloseModal} />}
      <section className="bg-white ">
        <nav className="bg-white  fixed w-full z-20 top-0 start-0 border-b border-gray-200 ">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a className="flex flex-col items-center space-x-3 rtl:space-x-reverse">
              <div className="flex flex-col cursor-default items-start text-[20px] font-bold leading-none lg:text-[22px]">
                <img src={logo} alt="" className="w-26 h-8" />
                <span className="text-xs text-gray-800 ml-3">for Busniess</span>
              </div>
            </a>
            <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
              <Link to="/">
              <button
                type="button"
                className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                Register
              </button>
                </Link>
              <button
                data-collapse-toggle="navbar-sticky"
                type="button"
                className="inline-flex flex-col items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200  "
                aria-controls="navbar-sticky"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>
            </div>
            <div
              className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
              id="navbar-sticky"
            ></div>
          </div>
        </nav>
        <div className="py-8 px-4 mt-6 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
          <div
            className="inline-flex justify-between mt-8 items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-200 rounded-full hover:bg-gray-300 dar"
            role="alert"
          >
            <span className="text-xs bg-green-600 rounded-full text-black px-4 py-1.5  mr-3">
              New
            </span>{" "}
            <span className="text-sm font-medium">
              GrowTo FB is out! See what's new
            </span>
            <svg
              className="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl ">
            Partner with GrowTo at 0% commission !{" "}
          </h1>
          <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 ">
            And get ads worth INR 1500. Valid for new Machine partners in select
            cities.
          </p>
          <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <div
             href="https://mugesh-rao.github.io/CashTrack/GrowTo-Biz.apk"
              download="GrowTo-Biz.apk"
              className="inline-flex justify-center cursor-pointer items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-green-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 "
            >
              Download App
              <svg
                className="ml-2 -mr-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            {/* <Link
              to="/"
              className="inline-flex justify-center cursor-pointer items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 "
            >
              Login
            </Link> */}
          </div>
        </div>
      </section>
      <section className="relative mb-8 py-8 xl:mb-0 xl:py-12 lg:py-[92px] bg-[#191C1F] overflow-x-hidden">
        <div className="flex flex-col items-center justify-center mb-6">
          <span className="text-4xl text-white font-extrabold text-center">
            Get Started with Listing
          </span>
        </div>
     
      </section>
      <section className="bg-white p-16">
      
      </section>
      <section className="relative mb-8 py-8 xl:mb-0 xl:py-12 lg:py-[92px] bg-[#1c4928] overflow-x-hidden">
        <div className="relative p-4 items-center justify-center flex flex-col">
          <h2 className="text-[34px]  font-bold text-white text-center ">
            Growto: Heavy Machinery for Business
          </h2>
          <p className="text-lg xl:text-[18px] text-gray-300 pb-6 xl:pb-9 xl:leading-[36px]">
            Increase efficiency and expand your reach with Growto's heavy
            machinery solutions. Tap into new markets with our extensive network
            and boost your brand visibility.
          </p>
          {/* Call-to-Action Buttons */}
          <div className="flex mt-5 xl:mt-0">
            <a
              className="block bg-green-900 text-white py-3 px-5 md:px-[40px] lg:text-lg rounded-full hover:scale-98 hover:bg-[#288A3F] transition-colors"
              href="#learn-more"
            >
              Learn More
            </a>
            <a
              className="block text-blue-500 py-3 px-5 md:px-[40px] lg:text-lg rounded-full flex flex-col items-center gap-2"
              href="#get-started"
            >
              <span>Get Started</span>
            </a>
          </div>
        </div>
      </section>
      <section className="relative mb-8 py-8 xl:mb-0 xl:py-12 lg:py-[92px] bg-white overflow-x-hidden">
        <div className="flex flex-col items-center justify-center">
          <span className="text-4xl font-extrabold text-center">
            How it works?
          </span>
        </div>
        <div className="relative p-4">
          {/* Step-by-step container */}
          <div className="flex flex-col gap-10 lg:flex-row lg:justify-between items-center">
            {/* Step 1 */}
            <div className="bg-gray-100  p-6 rounded-lg  border-gray-200 border  text-center flex flex-col justify-between w-full lg:w-1/3">
              <img
                src="https://via.placeholder.com/100" // Placeholder for step-related image
                alt="Step 1: Create your listing"
                className="mx-auto"
              />
              <h3 className="text-xl font-bold text-black mb-4">Step 1</h3>
              <p className="text-gray-900 font-semibold mb-6">
                Create your listing page on Growto. Showcase your heavy machines
                to attract more business.
              </p>
            </div>

            {/* Step 2 */}
            <div className="bg-gray-100  p-6 rounded-lg  border-gray-200 border  text-center flex flex-col justify-between w-full lg:w-1/3">
              <img
                src="https://via.placeholder.com/100" // Placeholder for step-related image
                alt="Step 1: Create your listing"
                className="mx-auto"
              />
              <h3 className="text-xl font-semibold text-black mb-4">Step 2</h3>
              <p className="text-gray-900 mb-6">
                Register for online transactions. Streamline payments and
                transactions with our secure platform.
              </p>
            </div>

            {/* Step 3 */}
            <div className="bg-gray-100  p-6 rounded-lg  border-gray-200 border  text-center flex flex-col justify-between w-full lg:w-1/3">
              <img
                src="https://via.placeholder.com/100" // Placeholder for step-related image
                alt="Step 1: Create your listing"
                className="mx-auto"
              />
              <h3 className="text-xl font-semibold text-black mb-4">Step 3</h3>
              <p className="text-gray-900 mb-6">
                Start receiving orders online. Use our partner app, web
                dashboard, or API integration for order management.
              </p>
            </div>
          </div>
        </div>
      </section>

    
    
   
      <Footer/>
    </div>
  );
}

export default Home;
